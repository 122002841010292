import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

export const logsPanelMinHeight = 300;

export const useStyles = () => {
  return useStyles2((theme: GrafanaTheme2) => ({
    grid: css({
      display: 'grid',
      gridTemplateRows: 'auto',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      alignItems: 'start',
      '> div': { alignSelf: 'start' },
    }),
    list: css({
      margin: theme.spacing(0, 2),
      '& > li': {
        marginBottom: theme.spacing(1),
      },
    }),
    container: css({
      backgroundColor: theme.colors.background.secondary,
      padding: theme.spacing(2),
    }),
    headingContainer: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
    actionPanel: css({
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    }),
    filterLabels: css({ display: 'flex', flexDirection: 'row' }),
    filterContainer: css({
      display: 'flex',
      flexDirection: 'column',
    }),
    groupByLabel: css({ width: '243px', marginRight: theme.spacing(2) }),
    selectContainer: css({
      display: 'block',
    }),
    selectGroup: css({
      display: 'flex',
      marginBottom: '10px',
      alignItems: 'top',
      gap: theme.spacing(2),
    }),
    addButton: css({
      padding: '0px 8px',
    }),
    deleteButton: css({
      padding: '0px 5px',
    }),
    negativeMarginLeft: css({
      marginLeft: '-1px',
    }),
    noBorderRadiusLeft: css({
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    }),
    noBorderRadiusRight: css({
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    }),
    noBorderRadius: css({
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderToLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    }),
    noData: css({
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(4),
      alignItems: 'center',
    }),
    errorMessage: css({
      position: 'relative',
    }),
    panelRenderer: css({
      width: '100%',
    }),
    spinner: css({
      paddingLeft: theme.spacing(1),
    }),
    skeletonContainer: css({
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '1',
      gap: theme.spacing(1),
    }),
    skeletonBg: css({
      backgroundColor: theme.colors.background.canvas,
      width: '100%',
      minHeight: '200px',
    }),
    queryControls: css({
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(3),
      '> div': { minWidth: '200px', marginRight: theme.spacing(2), marginBottom: theme.spacing(1) },
    }),
    graphPicker: css({
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    }),
    splitRow: css({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing(1),
    }),
    videoBlockResponsive: css({
      backgroundColor: theme.colors.background.secondary,
      // Video required
      position: 'relative',
      padding: '56.25% 0 0 0' /* 16:9 */,

      // half span from end
      gridColumn: '3 / span 2',
      '@media (max-width: 900px)': {
        gridColumn: '1 / span 4',
      },

      iframe: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(4)})`,
        height: `calc(100% - ${theme.spacing(4)})`,
        border: 'none',
      },
    }),
    overviewContainer: css({
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    }),
    fullSpan: css({
      gridColumn: '1 / span 4',
    }),
    gettingStartedResponsive: css({
      // display: 'flex',
      // flexDirection: 'column',
      // gap: theme.spacing(2),
      // half span from start
      gridColumn: '1 / span 2',
      '@media (max-width: 900px)': {
        gridColumn: '1 / span 4',
      },
    }),
    timeseriesContainer: css({
      marginTop: theme.spacing(1),
    }),
    gridGroup: css({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    }),
  }));
};
