import { DataFrame, dateTime, TimeRange } from '@grafana/data';

export const downloadCSV = (data: string, timeRange: TimeRange, filename: string) => {
  const formattedDate = `${dateTime(timeRange.to).format('YYYY-MM-DD-HH:mm:ss')}-${dateTime(timeRange.from).format('YYYY-MM-DD-HH:mm:ss')}`;
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.download = `${filename}-${formattedDate}.csv`;
  link.href = url;
  link.click();
};

// Define the type for the accumulator
type Accumulator = string[];

export const createCSV = (data: DataFrame[]): string | undefined => {
  try {
    if (data.length === 0 || data[0].fields.length === 0) {
      return undefined;
    }
    // Keep track of label value pairs for the csv header
    let totalFields = 0;
    let csvHeader: string[] = [];
    const dataFormatted = data[0].fields.reduce((acc: Accumulator, item, i) => {
      if (i > 0) {
        const csvRows = [];
        const allNames = item.name.split(/, /);
        totalFields = allNames.length;
        // Dynamically add label and value fields
        allNames.forEach((name) => {
          const [key, value] = name.split(/=(.*)/);
          // double quote to treat everything as a string
          csvHeader.push(`"${key.trim()}"`);
          csvRows.push(`"${value.trim()}"`);
        });
        // Add volume in bytes
        const volume = item.values[0];
        csvRows.push(volume);
        // Convert to CSV string
        const csvString = csvRows.join(',');
        acc.push(csvString);
      }
      return acc;
    }, []);
    csvHeader = csvHeader.splice(0, totalFields);
    csvHeader.push('"bytes"');
    const csvContent = [csvHeader, ...dataFormatted].join('\n');

    return csvContent;
  } catch (error) {
    console.error('Error processing CSV:', error);
    return;
  }
};
