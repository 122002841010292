export const testIds = {
  lve: {
    labelSelect: 'data-testid label-select',
    labelValueSelect: 'data-testid label-value-select',
    addQueryBtn: 'data-testid add-query-btn',
    deleteQueryBtn: 'data-testid delete-query-btn',
    toggleGraphBtn: 'data-testid toggle-graph-btn',
    linkToExploreBtn: 'data-testid link-to-explore-btn',
    exportCSVBtn: 'data-testid export-csv-btn',
  },
};
