import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DataSourcePicker, reportInteraction } from '@grafana/runtime';

import { buildExploreLink } from '@/components/LogVolume/LinkToExplore';
import { DEFAULT_DATASOURCE } from '@/constants/api';
import { buildQueryKey, ParamContext } from '@/context/param-context';
import { useGetVolume, useGetVolumeAll, useGetVolumeRange } from '@/hooks/logs';
import { useUrlSearchParamsUpdate } from '@/hooks/url.utils';
import { useStyles } from '@/styles';

import { Labels } from './Labels';
import { TimePicker } from './TimePicker';

export const ActionBar = () => {
  const styles = useStyles();
  const { param, setParam, setCurrentExploreLink, setCurrentQueryKey } = useContext(ParamContext);

  // Update UrlSearchParams
  const { urlSet } = useUrlSearchParamsUpdate(param);

  // Get log volume for the selected labels
  const { data: volumeData, refetch: refetchVolume, isInitialLoading, isRefetching } = useGetVolume();
  const { data: volumeRangeData, refetch: refetchVolumeRange } = useGetVolumeRange();
  const { data: volumeDataAll, refetch: refetchVolumeAll } = useGetVolumeAll();

  const isLoading = isInitialLoading || isRefetching;

  const handleClick = async () => {
    let valueCount = 0;

    param.labelVolume.forEach((volume) => {
      volume.value && valueCount++;
    });

    await setCurrentQueryKey(buildQueryKey(param));

    // create a table for big query to track amount of labels selected
    reportInteraction(`grafana_log_volume_explorer_app_submit_labels_clicked`, {
      label_value_pair_amount: param.labelVolume.length,
      value_amount: valueCount,
    });

    // manually call volume and volume_range
    refetchVolume();
    refetchVolumeRange();
    refetchVolumeAll();
    setCurrentExploreLink(buildExploreLink(param));
    urlSet(param);
  };

  useEffect(() => {
    // Url Params are set run volume query
    if (param.labelVolume[0] && param.labelVolume[0].label !== null) {
      if (!volumeData?.data.result.length) {
        refetchVolume();
      }
      if (!volumeRangeData?.data.result.length) {
        refetchVolumeRange();
      }
      if (!volumeDataAll?.data.result.length) {
        refetchVolumeAll();
      }
      urlSet(param);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const methods = useForm();
  const {
    handleSubmit,
    setValue,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = methods;

  return (
    <div>
      <div className={styles.headingContainer}>
        <h2>Select a combination of labels, values and time range</h2>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleClick)}>
          <div className={styles.queryControls}>
            <DataSourcePicker
              type="loki"
              noDefault={true}
              alerting={true}
              current={param.datasource}
              onChange={(ds) => {
                reset();
                setParam({
                  ...param,
                  datasource: ds.uid || DEFAULT_DATASOURCE,
                });
              }}
            />
            <TimePicker />
          </div>
          <hr />
          <div className={styles.actionPanel} key={param.datasource}>
            <Labels
              isLoading={isLoading}
              clearErrors={clearErrors}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
