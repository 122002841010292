import { useContext } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { dateTime } from '@grafana/data';
import { FetchError } from '@grafana/runtime';

import { get } from '@/api/request';
import { LOKI_INTERNAL_LABELS } from '@/constants';
import { DEFAULT_DATASOURCE } from '@/constants/api';
import { ParamContext } from '@/context/param-context';
import { LabelVolume, Param } from '@/types';
import { LogLabels, LogStats } from '@/types/logs';
import { LogVolumeRangeResponse, LogVolumeResponse } from '@/types/logVolume';

import { getPluginDataProxyUrl, timeToUnixEpoch } from './utils';

export const useGetLogStats = (params: Param): UseQueryResult<LogStats, FetchError> => {
  const { labelVolume, timeRange } = params;
  const query = labelVolume
    .map((l) => {
      // Transform label to query string to match all label keys of that string
      // query={app=~".+",job=~".+"}
      if (l.value === '' || !l.value) {
        return `${l.label}=~".+"`;
      }
      // Transform label to query string label=value
      // query={app="thing",job="things"}
      return `${l.label}="${l.value}"`;
    })
    .join(',');

  return useQuery(
    ['stats'],
    () => {
      return get(`${getPluginDataProxyUrl(params.datasource)}/api/v1/index/stats`, {
        query: `{${query}}`,
        start: timeToUnixEpoch(timeRange.from),
        end: timeToUnixEpoch(timeRange.to),
      });
    },
    {
      enabled: false, // disable query until user clicks on submit
      staleTime: Infinity, // never refetch until queryClient is invalidated
    }
  );
};

export const getLogLabels = async (param: Param): Promise<string[]> => {
  const { labelVolume, datasource, timeRange } = param;
  if (labelVolume.filter((l) => l.label !== '' && l.label).length < 1) {
    const res = await get<LogLabels>(`${getPluginDataProxyUrl(datasource)}/api/v1/labels`, {
      start: timeToUnixEpoch(timeRange.from),
      end: timeToUnixEpoch(timeRange.to),
    });
    let results = res.data.filter((label) => {
      return !LOKI_INTERNAL_LABELS.some((l) => label === l);
    });
    return results || [];
  }

  const query = buildQueryStringParts(labelVolume).join(',');

  const lvResp = await get<LogVolumeResponse>(`${getPluginDataProxyUrl(datasource)}/api/v1/index/volume`, {
    query: `{${query}}`,
    start: timeToUnixEpoch(timeRange.from),
    end: timeToUnixEpoch(timeRange.to),
    aggregateBy: 'labels',
  });
  let labels = lvResp.data.result.flatMap(({ metric }) => Object.keys(metric)) || [];

  // Remove labels that have already been selected
  let selectedLabels = labelVolume.map((l) => l.label);
  // Remove internal labels
  let results = labels.filter((label) => {
    return !LOKI_INTERNAL_LABELS.some((l) => label === l);
  });
  results = labels.filter((label) => !selectedLabels.includes(label));

  return results;
};

export const useGetLogLabels = (id: string, param: Param): UseQueryResult<string[], FetchError> => {
  return useQuery(buildLogLabelsKey(id, param), () => getLogLabels(param), {
    staleTime: Infinity, // never refetch until queryClient is invalidated
  });
};

export const buildLogLabelsKey = (id: string, param: Param): string[] => {
  const { datasource } = param;

  return ['labels', datasource, id];
};

const sortLabelVolumes = (labelVolume: LabelVolume[]): LabelVolume[] =>
  [...labelVolume].sort((a, b) => {
    if (!b.label) {
      return -1;
    }
    if (!a.label) {
      return 1;
    }
    if (a.label === b.label) {
      return 0;
    }

    if (a.label < b.label) {
      return -1;
    }
    return 1;
  });

export const buildQueryStringParts = (labelVolume: LabelVolume[]): string[] =>
  labelVolume
    .filter((l) => l.label && l.label !== '')
    .map((l: LabelVolume) => {
      if (l.value === '' || !l.value) {
        return `${l.label}=~".+"`;
      }
      return `${l.label}="${l.value}"`;
    });

export const getLogLabelValues = async (label: string | null, param: Param): Promise<string[]> => {
  if (!label) {
    return [];
  }
  const { labelVolume, datasource, timeRange } = param;

  let reqParams: {
    start: number;
    end: number;
    query?: string | undefined;
  } = {
    start: timeToUnixEpoch(timeRange.from),
    end: timeToUnixEpoch(timeRange.to),
  };

  const nonEmptyLabels = sortLabelVolumes(labelVolume.filter((l) => l.label && l.label !== ''));
  if (nonEmptyLabels.length < 1) {
    const res = await get<LogLabels>(`${getPluginDataProxyUrl(datasource)}/api/v1/label/${label}/values`, reqParams);
    return res.data || [];
  }

  const query = buildQueryStringParts(nonEmptyLabels).join(',');
  if (query) {
    reqParams.query = `{${query}}`;
  }

  const lvResp = await get<LogLabels>(`${getPluginDataProxyUrl(datasource)}/api/v1/label/${label}/values`, reqParams);

  return lvResp.data || [];
};

export const buildLogLabelValuesKey = (label: string | null, param: Param): string[] => {
  const { labelVolume, datasource } = param;
  const keyPrefix = ['labelValues', datasource];

  if (!label) {
    return keyPrefix;
  }

  const selectorId = labelVolume.find((l) => l.label === label)?.id;
  return keyPrefix.concat(selectorId ? [label, selectorId] : [label]);
};

// Potentially very expensive query with 10K + results
export const useGetLogLabelValues = (label: string | null, param: Param): UseQueryResult<string[], FetchError> => {
  return useQuery(buildLogLabelValuesKey(label, param), () => getLogLabelValues(label, param), {
    staleTime: Infinity, // never refetch until queryClient is invalidated
  });
};

export const useGetTopVolume = (): UseQueryResult<LogVolumeResponse, FetchError> => {
  return useQuery(
    ['topLabelVolume', DEFAULT_DATASOURCE],
    () => {
      return get(`${getPluginDataProxyUrl(DEFAULT_DATASOURCE)}/api/v1/index/volume`, {
        query: `{}`,
        limit: 10,
        aggregateBy: 'labels',
        start: timeToUnixEpoch(dateTime().subtract(1, 'hour')),
        end: timeToUnixEpoch(dateTime()),
      });
    },
    {
      enabled: false /* prevent query from running automatically, instance may not have the default datasource */,
    }
  );
};

export const useGetVolume = (): UseQueryResult<LogVolumeResponse, FetchError> => {
  const {
    param: { datasource, labelVolume, timeRange },
    currentQueryKey,
  } = useContext(ParamContext);

  // Transform label to query string to match all label keys of that string query={app=~".+",job=~".+"}
  const query = labelVolume
    .map((l) => {
      if (l.value === '' || !l.value) {
        return `${l.label}=~".+"`;
      }
      return `${l.label}="${l.value}"`;
    })
    .join(',');

  return useQuery(
    ['labelVolume', datasource, currentQueryKey],
    () => {
      return get(`${getPluginDataProxyUrl(datasource)}/api/v1/index/volume`, {
        query: `{${query}}`,
        limit: 10,
        start: timeToUnixEpoch(timeRange.from),
        end: timeToUnixEpoch(timeRange.to),
      });
    },
    {
      enabled: false /* prevent query from running until user clicks submit */,
    }
  );
};

export const useGetVolumeRange = (): UseQueryResult<LogVolumeRangeResponse, FetchError> => {
  const {
    param: { datasource, labelVolume, timeRange },
    currentQueryKey,
  } = useContext(ParamContext);

  const query = buildQueryStringParts(labelVolume);

  return useQuery(
    ['volumeRange', datasource, currentQueryKey],
    () => {
      return get(`${getPluginDataProxyUrl(datasource)}/api/v1/index/volume_range`, {
        query: `{${query}}`,
        limit: 10,
        start: timeToUnixEpoch(timeRange.from),
        end: timeToUnixEpoch(timeRange.to),
      });
    },
    {
      enabled: false /* prevent query from running until user clicks submit */,
    }
  );
};

export const useGetVolumeAll = (): UseQueryResult<LogVolumeResponse, FetchError> => {
  const {
    param: { datasource, labelVolume, timeRange },
    currentQueryKey,
  } = useContext(ParamContext);

  // Transform label to query string to match all label keys of that string query={app=~".+",job=~".+"}
  const query = labelVolume
    .map((l) => {
      if (l.value === '' || !l.value) {
        return `${l.label}=~".+"`;
      }
      return `${l.label}="${l.value}"`;
    })
    .join(',');

  return useQuery(
    ['labelVolumeAll', datasource, currentQueryKey],
    () => {
      return get(`${getPluginDataProxyUrl(datasource)}/api/v1/index/volume`, {
        query: `{${query}}`,
        start: timeToUnixEpoch(timeRange.from),
        end: timeToUnixEpoch(timeRange.to),
      });
    },
    {
      enabled: false /* prevent query from running until user clicks submit */,
    }
  );
};
