import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@grafana/ui';

import { getBarChartFrame } from '@/components/LogVolume/utils';
import { testIds } from '@/components/testIds';
import { ParamContext } from '@/context/param-context';
import { useGetVolumeAll } from '@/hooks/logs';
import { useStyles } from '@/styles';
import { createCSV, downloadCSV } from '@/utils/utils.csv';

export const ExportCSV = () => {
  const {
    param: { timeRange },
  } = useContext(ParamContext);

  // Get all log volume for CSV
  const { data } = useGetVolumeAll();

  const styles = useStyles();
  const [CSVdata, setCSVdata] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (data) {
      let volumeData = data?.data.result;
      const volumeDataDF = getBarChartFrame(volumeData);
      if (volumeDataDF?.series) {
        let csv = createCSV(volumeDataDF.series);
        setCSVdata(csv);
      }
    }
  }, [data]);

  // Download CSV file
  const downloadLogVolume = () => {
    if (!CSVdata) {
      return;
    }
    return downloadCSV(CSVdata, timeRange, 'log-volume');
  };

  return (
    <Button
      data-testid={testIds.lve.exportCSVBtn}
      className={styles.timeseriesContainer}
      disabled={!CSVdata}
      variant="secondary"
      icon="file-download"
      onClick={downloadLogVolume}
    >
      Download (CSV)
    </Button>
  );
};
