import React, { useContext } from 'react';

import { LinkButton } from '@grafana/ui';

import { testIds } from '@/components/testIds';
import { ParamContext } from '@/context/param-context';
import { buildQueryStringParts } from '@/hooks/logs';
import { useStyles } from '@/styles';
import { Param } from '@/types';

export const buildExploreLink = ({ labelVolume, timeRange, datasource }: Param) => {
  const queryStr = `{${buildQueryStringParts(labelVolume).join(',')}}`;
  const left = [
    encodeURIComponent(
      JSON.stringify({
        datasource: datasource,
        queries: [{ expr: queryStr }],
        range: {
          from: timeRange.from,
          to: timeRange.to,
        },
      })
    ),
  ];

  return `/explore?left=${left}`;
};

export const LinkToExplore = () => {
  const { currentExploreLink } = useContext(ParamContext);
  const styles = useStyles();

  return (
    <LinkButton
      className={styles.timeseriesContainer}
      icon="compass"
      variant="secondary"
      data-testid={testIds.lve.linkToExploreBtn}
      href={currentExploreLink}
      target={'_blank'}
    >
      View logs in Explore
    </LinkButton>
  );
};
